<template>
  <nav id="navbar" :class="{resp : menuopen}">
    <div class="nav-notify" v-if="notification">
      <p v-html="notification"></p>
    </div>
    <div class="nav-cont" :class="{resp : menuopen}">
      <img alt="Vue logo" src="./assets/logo.webp">
      <div id="nav-links" :class="{resp : menuopen}">
        <router-link to="/">Főoldal</router-link>
        <router-link to="/infoboard">Információk</router-link>
        <a href="http://dc.blackcityrp.hu/">Discord</a>
        <router-link to="/panel">Támogatás</router-link>
        <p id="menu" @click="menuopen = !menuopen" :class="{resp : menuopen}"><i v-if="menuopen" class="bi bi-x"></i> <i v-else class="bi bi-list"></i></p>
      </div>
    </div>
  </nav>
  <div id="root-view">
    <router-view/>
  </div>
  <div id="footer">
    <div class="footer-cont">
      <div class="main-footer">
        <img alt="Vue logo" src="./assets/logo.webp">
        <div class="legal-infos">
          <p>Black City RolePlay (c) 2021-2025</p>
          <p>Adószám: 90430169-1-23</p>
        </div>
      </div>
      <div class="payment-methods">
        <img src="./assets/stripe.webp" alt="">
        <img src="./assets/paypal.webp" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import AOS from 'aos'
export default {
  name: 'App',
  data() {
    return {
      menuopen:false,

      notification:false
    }
  },
  async mounted() {
    AOS.init()

    try {
      const response = await fetch("/api/public/notification")
      const notif = await response.text()
      if (!notif || notif.includes("Cannot GET") || notif.includes("false")) {
        this.notification = false
        return
      }
      this.notification = notif
    } catch {
      this.notification = false
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Victor+Mono:wght@100&display=swap');*/
#app {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  /*font-family: 'Kiwi Maru', serif;*/
  font-family: "Afacad Flux", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #1F1F1F;
}

body {
  width: 100%;
  background-color: #2E2E2E;
}

#navbar {
  position: relative;
  height: auto;
  width: 100%;
  background-color: #2E2E2E;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 19px 20px -6px rgba(0,0,0,0.65);
  -webkit-box-shadow: 0px 19px 20px -6px rgba(0,0,0,0.65);
  -moz-box-shadow: 0px 19px 20px -6px rgba(0,0,0,0.65);
  z-index: 100;
}
.nav-cont {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vw;
  width: 100%;
  padding-left: 10vw;
  padding-right: 10vw;
}
#navbar img {
  height: 80%;
}

#navbar a {
  font-weight: bold;
  color: white;
  margin-left: 25px;
}

#navbar a.router-link-exact-active {
  color: #364FC7;
}

.nav-notify {
  background-color: #364FC7;
  color: white;
  display: flex;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0px -8px 15px -5px rgba(0,0,0,0.75) inset;
  padding-right: 10vw;
  padding-left: 10vw;
}

#root-view {
  width: 100%;
}

#footer {
  height: 8vw;
  height: fit-content;
  background-color: #2E2E2E;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  box-shadow: 0px -19px 20px -6px rgba(0,0,0,0.65);
  -webkit-box-shadow: 0px -19px 20px -6px rgba(0,0,0,0.65);
  -moz-box-shadow: 0px -19px 20px -6px rgba(0,0,0,0.65);
  z-index: 100;
}

.footer-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: fit-content;
}

.payment-methods img {
 width: 10vw;
 height: auto;
 margin: 15px;
}

.main-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: fit-content;
  width: 80vw;
}

.main-footer img {
  height: 10vw;
}

.legal-infos {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;

}
.legal-infos p {
  margin: 0;

}

#menu {
  display: none;
  color: white;
  font-size: 140%;
}

@media (min-width: 650px) and (max-width: 861px) {
  #navbar {
    height: auto;
  }
  .nav-cont {
    height: 10vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .nav-notify {
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .footer-cont {
    width: 95%;
  }
}

@media  (max-width: 651px) {
  #navbar {
    height: auto;
  }
  .nav-cont {
    height: 22vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .nav-notify {
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .footer-cont {
    width: 95%;
    flex-direction: column;
  }
  .main-footer {
    width: 95%;
    flex-direction: column;
  }
  .legal-infos {
    align-items: center;
  }
  #nav-links #menu {
    display: block;
    font-size: 220%;
  }
  #nav-links a {
    display: none;
  }
  #nav-links {
    display: flex;
    flex-direction: column;
  }

  #nav-links.resp a {
    display: block;
    margin: 3px;
  }
  #nav-links.resp {
    height: 100%;
  }
  #navbar.resp {
    height: auto;
    padding-bottom: 10px;
  }
  .nav-cont.resp {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
  #navbar.resp img {
    display: none;
  }
  #nav-links #menu.resp {
    margin:0;
  }
}
</style>
